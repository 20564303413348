@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
@import 'node_modules/quasar/src/css/index';

// app global css in SCSS form
html {
  background: whitesmoke;
}

@font-face {
  font-family: Sofia_Pro_Medium;
  src: url('~assets/Sofia_Pro_Medium.ttf');
}

@font-face {
  font-family: Sofia_Pro_Regular;
  src: url('~assets/Sofia_Pro_Regular.ttf');
}

.text-body1 {
  font-size: 140px;
}

.sa-markup-table {
  background: white;

  thead {
    background: $sa-Paars;
    th {
      width: 150px;
      padding-top: 20px;
      padding-bottom: 20px;
      font-size: 16px;
      font-weight: 400;
      margin-top: 5px;
      margin-bottom: 10px;
      font-family: Sofia_Pro_Medium;
      color: white;
    }
  }
  tbody {
    .td-first {
      font-size: 18px;
    }
    td {
      padding-bottom: 15px;
      padding-top: 15px;
      font-size: 15px;
    }
  }

  .q-badge {
    background-color: $sa-Koraal;
  }
}
